import React from 'react'

export default function TrucksInfo() {
    return (
        <section id="truckInfo" className="fancybox-layout2 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="heading heading-3 mb-50">
                            <span className="heading__subtitle small-title blue">Nuestras unidades</span>
                            <h2 className="heading__title white">El motor que nos mueve</h2>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="heading heading-3 mb-50">
                            <p className="service-more-desc white">Nuestras unidades de transportación cuentan con dispositivos para su localización satelital (GPS)</p>
                            <p>Somos colaboradores que estamos comprometidos con nuestros clientes, en consecuencia aseguramos continuamente.</p>

                        </div>
                    </div>

                </div>
                <div className="row truck-info">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-tractor-"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title white">Tractocamiones</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-truck"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title white">Camiones</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-distribution"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title white">Camionetas 3.5 Ton</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-low-bed"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title white">Plataformas</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-dry-box"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title white">Cajas secas de 53'</h4>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="fancybox-item">
                            <div className="fancybox__icon">
                                <i className="icon-low-bed"></i>
                            </div>
                            <div className="fancybox__content">
                                <h4 className="fancybox__title white">Camas bajas</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
